@import "../../../styles/config.scss";

.wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  background: $light-grey;
}

.contentWrapper {
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: auto;

  > * {
    width: 50%;
    border-right: 1px solid $grey;

    &:last-child {
      border-right: 0;
    }
  }
}
