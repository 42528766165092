@import "../../../../styles/config.scss";

.wrapper {
  padding: $unit;
}

.inform {
  padding: $unit * 0.5 $unit;
  background: $white;
  border-radius: 4px;
  margin-bottom: $unit;

  p {
    line-height: 1;
  }
  img {
    vertical-align: middle;
    margin-right: $unit * 0.5;
  }
}

.selectedInformation {
  display: flex;
  margin-bottom: $unit;
  align-items: center;

  > * {
    margin-right: $unit * 3;

    &:last-child {
      margin-right: 0;
    }
  }
}

.buttons {
  display: flex;

  > .button {
    width: auto;
    margin-bottom: 0;
    text-transform: inherit;
  }
}
