@import "../../../../styles/config.scss";

.wrapper {
  padding: $unit * 0.5 $unit * 0.5 0;
  border-bottom: 3px solid $grey;
}

.statusWrapper {
  display: flex;

  > * {
    margin-right: $unit * 0.5;
    cursor: pointer;

    p {
      text-transform: uppercase;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      position: relative;
      padding: 0 $unit * 0.125 $unit * 0.5;

      span {
        margin-left: $unit * 0.25;
      }
    }

    &.active {
      p {
        color: $green;

        &:after {
          content: "";
          border-bottom: 3px solid $green;
          position: absolute;
          bottom: -3px;
          left: 0;
          width: 100%;
        }
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
