@import "../../../styles/config.scss";

.button {
  transition: opacity $transition-fast ease-in-out;
  font-family: $font-family;
  font-weight: bold;

  + .button {
    margin-left: $unit;
  }

  &:hover {
    opacity: 0.5;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.button:not(.link) {
  background-color: $primary-color;
  color: $white;
  border-radius: 6px;
  border: 1px solid $primary-color;
  padding: 0 $unit;
  font-size: $font-size;
  line-height: $line-height;
  cursor: pointer;
  margin-bottom: $unit;
  text-decoration: none;
  display: block;
  width: 100%;
  outline: 0;
  text-align: center;

  &:last-child {
    margin-bottom: 0;
  }
}

.buttonSimple {
  color: $grey;
  border: 0;
  padding: 0;
  text-transform: lowercase;
  display: inline;

  &:hover {
    text-decoration: underline;
  }
}

.buttonBig {
  padding: $unit $unit * 0.75;
  text-align: center;
}

.button.contrast {
  border: 1px solid $primary-color;
  background: $white;
  color: $primary-color;
}

.link {
  background: transparent;
  border: 0;
  font-size: $font-size-sm;
  line-height: $line-height-sm;
  text-decoration: underline;
  cursor: pointer;
  color: $black;
}

.fat {
  font-weight: bold;
}

@media only screen and (min-width: $screen-sm-min) {
  .button:not(.link) {
    padding: $unit * 0.5 $unit * 2;
    font-size: $font-size-sm;
    line-height: $line-height-sm;

    &.buttonSmall {
      padding: $unit * 0.5 $unit * 0.75;
      text-align: center;
      font-size: $font-size-sm;
      line-height: $line-height-sm;
    }
  }

  .buttonBig {
    padding: $unit $unit * 0.5;
    text-align: center;
    width: auto;
  }

  .buttonSimple {
    padding: 0;
  }
}
