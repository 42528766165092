@import "../../../styles/config.scss";

.wrapper {
  padding: $unit * 3 0;
  text-align: center;
}

.logo {
  width: 120px;
  margin: 0 auto;
  display: block;
  margin-bottom: $unit * 3;
}

.hostsWrapper {
  display: flex;
  margin-bottom: $unit;

  li {
    cursor: pointer;
    margin: 0 $unit * 0.5;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:first-child {
      margin-left: auto;

      figure {
        margin-left: $box-shadow-default;
      }
    }

    &:last-child {
      margin-right: auto;

      figure {
        margin-right: $box-shadow-default;
      }
    }

    p {
      margin: $unit * 0.5 0 0;
      text-align: center;
      text-transform: uppercase;
    }
  }
}

.formWrapper {
  width: $unit * 20;
  margin: 0 auto;

  input,
  select {
    text-align-last: center;
  }
}
