@import "../../../styles/config.scss";

.wrapper {
  color: $white;
  border: 1px solid $green;
  background: $green;
  font-size: $font-size-sm-small;
  line-height: 1;
  padding: $unit * 0.125 $unit * 0.25;
  display: inline-block;
  border-radius: $unit * 0.5;
  vertical-align: text-top;

  + .wrapper {
    margin-left: $unit * 0.5;
  }
}

.contrast {
  background: transparent;
  color: $green;
}
