@import "../../../../styles/config.scss";

.location {
  display: flex;
  padding: $unit * 0.25 $unit;
  margin: 0 -1 * $unit;
  border-top: 1px solid $grey;
  align-items: center;
  cursor: pointer;
  min-height: $unit * 3;

  .imageWrapper {
    width: $unit * 4;
    margin-right: $unit;
  }

  img {
    max-width: $unit * 4;
    max-height: $unit * 4;
  }

  p {
    margin-bottom: 0;

    &.locationType {
      color: $dark-grey;

      &::first-letter {
        text-transform: uppercase;
      }
    }
  }
}
