@import "../../../styles/config.scss";

.wrapper {
}

div.table {
  border: 0;
}

:global {
  .rt-tr.-odd {
    background: white;
  }

  div.ReactTable .rt-thead .rt-th.-sort-asc,
  div.ReactTable .rt-thead .rt-td.-sort-asc {
    box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.6);
  }
  div.ReactTable .rt-thead .rt-th,
  div.ReactTable .rt-thead .rt-td {
    border-right: 0;
  }

  div.ReactTable .rt-tbody .rt-td {
    border-right: 0;
  }

  div.ReactTable .rt-th,
  div.ReactTable .rt-td {
    padding: 0;

    > * {
      padding: $unit * 0.5 5px;
      text-align: center;
      justify-content: center;
    }
  }
}

.reservationColumn {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 4px;
  a {
    // color: white;
  }
}
