@import "../../../styles/config.scss";

.h1 {
  font-family: $font-family-alternative;
  font-size: $font-size-big;
  line-height: $line-height-big;
  margin-bottom: $unit;
  margin-top: 0;
  font-weight: bold;

  &:last-child {
    margin-bottom: 0;
  }
}

// @media only screen and (min-width: $screen-sm-min) {
//   .h1 {
//     font-size: $font-size-sm-big;
//     line-height: $line-height-sm-big;
//   }
// }
