@import "../../../../styles/config.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: $very-light-grey;

  > h3 {
    margin: $unit $unit 0;
    font-weight: 700;
    font-size: $font-size-sm-medium;
    line-height: $line-height-sm-medium;
  }
}

.content {
  flex-grow: 1;
  overflow: auto;
  padding: $unit;
}

.footer {
  padding: $unit * 0.5 $unit;
  display: flex;
  align-items: center;
  background: $white;

  .avatars {
    flex-grow: 1;
    display: flex;

    > :first-child {
      margin-right: -1 * $unit;
      position: relative;
      z-index: 1;
    }
  }

  > * {
    margin-right: $unit;

    &:last-child {
      margin-right: 0;
    }
  }
}
