@import "../../../styles/config.scss";

.wrapper {
  position: relative;

  img {
    position: absolute;
    width: $unit;
    height: $unit;
    top: calc(50% - #{$unit * 0.5});
  }

  &.search {
    img {
      left: $unit * 0.5;
    }

    .input {
      padding-left: $unit * 2;
    }
  }

  &.message {
    img {
      right: $unit * 0.5;
      cursor: pointer;
    }

    .input {
      padding-right: $unit * 2;
    }
  }
}

.input {
  width: 100%;
  display: block;
  height: $unit * 2;
  margin: 0 0 $unit 0;
  font-size: $font-size-medium;
  line-height: $line-height-medium;
  padding: 0 $unit * 0.5;
  border-radius: $unit * 0.5;
  border: 1px solid $grey;
  color: $black;
  font-family: inherit;

  &::placeholder {
    color: $dark-grey;
  }

  &:focus {
    outline: 0;
  }
}

.checkbox {
  display: inline;
}
