@import "../../../styles/config.scss";

.wrapper {
  position: relative;

  img {
    position: absolute;
    width: $unit;
    height: $unit;
    top: $unit * 0.5;
  }

  &.message {
    img {
      right: $unit * 0.5;
      cursor: pointer;
    }

    .input {
      padding-right: $unit * 2;
    }
  }
}

.textArea {
  width: 100%;
  display: block;
  height: $unit * 2;
  margin: 0 0 $unit 0;
  font-size: $font-size-medium;
  line-height: $line-height-medium;
  padding: $unit * 0.4 $unit * 0.5;
  border-radius: $unit * 0.5;
  border: 1px solid $grey;
  color: $black;
  font-family: inherit;
  box-sizing: border-box;
  resize: none;

  &:focus {
    outline: 0;
  }

  &::placeholder {
    color: $dark-grey;
  }
}

.checkbox {
  display: inline;
  width: auto;
  height: auto;
}
