@import "../../../../styles/config.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  min-height: 0px;
  padding: $unit * 2 $unit $unit;
  max-height: 100%;
}

.messageItem {
  display: flex;

  > * {
    + * {
      margin-left: $unit;
    }
  }

  + .messageItem {
    margin-top: $unit;
  }

  &.messageItemReversed {
    flex-direction: row-reverse;

    > * {
      + * {
        margin-left: 0;
        margin-right: $unit;
      }
    }

    .messageText {
      &:before {
        display: none;
      }
      &:after {
        display: block;
      }
    }
  }
}
.message {
  flex-grow: 1;
}

.messageText {
  border: 1px solid $light-grey;
  border-radius: $unit / 5;
  padding: $unit / 2 $unit / 2 $unit * 1.5;
  position: relative;
  min-height: $unit * 3.5;
  white-space: pre-wrap;

  &:before,
  &:after {
    width: $unit / 2;
    height: $unit / 2;
    border-top: 1px solid $light-grey;
    border-left: 1px solid $light-grey;
    background: $white;
    content: "";
    position: absolute;
    top: $unit * 1.5;
  }
  &:before {
    transform: rotate(-45deg);
    left: -6px;
  }

  &:after {
    transform: rotate(135deg);
    right: -6px;
    display: none;
  }

  &.messagePlace {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 0;
  }
}

.messageBroadcast {
  background: $light-green;

  &:before,
  &:after {
    background: $light-green;
  }
}

.place {
  border-bottom-left-radius: $unit / 5;
  border-bottom-right-radius: $unit / 5;
  overflow: hidden;
}

.messageSender {
  position: absolute;
  bottom: $unit * 0.25;
  right: $unit * 0.5;
}

.messageMeta {
  display: flex;
  justify-content: space-between;
  padding-top: $unit * 0.25;

  p {
    color: $grey;
    margin-bottom: 0;
    text-transform: uppercase;
  }

  .broadcastLabel {
    color: $black;
    text-transform: uppercase;

    img {
      // vertical-align: text-bottom;
    }
  }
}
