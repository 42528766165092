@import "../../../styles/config.scss";

.wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  background: white;
  width: $unit * 31;
  height: $unit * 15;
  z-index: 2;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.25);
  padding: $unit;
  display: flex;
  flex-direction: column;
}

.close {
  margin-left: auto;
  cursor: pointer;
  transition: opacity 100ms ease-in;

  &:hover {
    opacity: 0.5;
  }
}
