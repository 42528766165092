@import "../../styles/config.scss";

.wrapper {
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: $screen-sm-min) {
  .wrapper {
    width: 100%;
  }
}
