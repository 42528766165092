@import "../../../styles/config.scss";

.mapContainer {
  width: 100%;
  height: 100%;
}

.searchInputWrapper {
  position: absolute;
  top: $unit * 2;
  left: $unit;
  right: $unit;
  max-width: 630px;
  margin: 0 auto;
}
.selectedPlaceWrapper {
  position: absolute;
  bottom: $unit;
  left: $unit;
  right: $unit;
}
