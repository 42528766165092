@import "../../../styles/config.scss";

.wrapper {
  height: 100vh;

  > * {
    height: 100vh;
  }
}

.scanWrapper {
  $width: $unit * 34;

  position: absolute;
  bottom: $unit;
  height: auto;
  width: $width;
  left: calc(50% - #{$width / 2});

  > img {
    margin: 0 auto $unit * -3 auto;
    display: block;
  }
}

.scanText {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $unit * 3 $unit * 4;
  background: $very-light-grey;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);

  p {
    margin-bottom: 0;
  }
}

.loadingWrapper {
  $size: 215px; // Some finetuning

  background: $very-light-grey;
  width: $size;
  height: $size;
  z-index: 2;
  position: relative;

  border-radius: $size / 2;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.5);

  margin: 0 auto ($unit * -3)+13 auto; // Some finetuning
  display: flex;
  justify-content: center;
  align-items: center;
}

.fullScreenButton {
  position: absolute;
  top: $unit;
  right: $unit;
  width: 32px;
  height: 18px;
  z-index: 1;
  background: transparent;
  border: 2px solid $dark-grey;
  cursor: pointer;

  transition: opacity 100ms ease-in-out;

  &:hover {
    opacity: 0.5;
  }
}
