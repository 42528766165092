@import "../../../../styles/config.scss";

.wrapper {
  background: $light-grey;
  padding: $unit * 0.5 $unit;
  display: flex;
  align-items: flex-end;

  > .icon {
    height: $unit * 2;
    display: flex;
    flex-shrink: 0;
    cursor: pointer;
  }

  .input {
    flex-grow: 1;
  }

  textarea {
    margin-bottom: 0;
  }

  > * {
    margin-right: $unit * 0.5;

    &:last-child {
      margin-right: 0;
    }
  }
}

.locationButton {
  cursor: pointer;
  width: 12px;
}
