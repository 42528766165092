@import "../../../styles/config.scss";

.wrapper {
  display: flex;

  p {
    margin-bottom: 0;
  }

  > * {
    margin-right: $unit * 0.75;

    &:not(img) {
      padding: $unit * 0.75 0;
    }
  }
}

.wrapperMap {
  background: $white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.wrapperForm {
  background: $light-grey;
  padding: $unit $unit $unit * 0.5;

  > :last-child {
    margin-right: 0;
  }
}

.wrapperMessage {
  height: $unit * 15;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: $unit;
  padding-right: $unit;

  .image {
    position: absolute;
    top: 0;
    left: 0;
    max-height: initial;
    height: $unit * 15;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-color: $dark-grey;
  }

  .description {
    position: relative;
    flex-grow: 0;

    p {
      color: $white;
      text-shadow: 0 1px 0 $black;

      &.title {
        font-size: $font-size-sm-medium;
        line-height: $line-height-sm-medium;
      }

      &.subtitle {
        font-size: $font-size-sm-small;
        line-height: $line-height-sm-small;
      }
    }
  }
}

.image {
  height: 100px;

  &.fallbackImage {
    min-width: 150px;
    background: $dark-grey;
  }
}

.description {
  flex-grow: 1;
}

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;

  button {
    margin-bottom: 0;
  }
}

.closeButton {
  cursor: pointer;
}
