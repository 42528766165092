@import "../../../styles/config.scss";

.starsContainer {
  img {
    margin-right: $unit * 0.25;

    &:last-child {
      margin-right: 0;
    }
  }
}
