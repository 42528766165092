@import "../../../../styles/config.scss";

.wrapper {
  padding: $unit;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: $unit;

  h1 {
    margin-bottom: 0;
  }
}

.linked {
  text-decoration: underline;
  cursor: pointer;
}

.reservationColumnActive {
  background: $light-green;
}

.reservationColumnInactive {
  background: $light-red;
}

.reservationColumnWarning {
  background: $light-yellow;
}
