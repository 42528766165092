@import "../../../styles/config.scss";

.wrapper {
  position: absolute;
  width: calc(100% - #{$unit * 4.5});
  padding: $unit $unit $unit $unit * 0.5;
  background: $light-green;
  left: $unit * 4.5;
  top: 0;
  z-index: 3;
  display: flex;
  justify-content: space-between;

  p {
    color: $green;
    margin-bottom: 0;
  }
}

.wrapperHide {
  opacity: 1;
  animation: hide-notification 300ms;
  animation-fill-mode: forwards;
  overflow: hidden;
}

@keyframes hide-notification {
  0% {
    opacity: 0.5;
    height: auto;
    padding: $unit $unit $unit $unit * 0.5;
  }
  80% {
    opacity: 0;
    height: auto;
    padding: $unit $unit $unit $unit * 0.5;
  }
  100% {
    height: 0;
    padding: 0;
    opacity: 0;
  }
}

.close {
  cursor: pointer;
  transition: opacity 100ms ease-in;
  color: $green;

  &:hover {
    opacity: 0.5;
  }
}
