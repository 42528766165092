@import "config";

@font-face {
  font-family: "BrownWeb";
  src: url(./fonts/lineto-brown-regular.eot);
  src: url(./fonts/lineto-brown-regular.eot?#iefix) format("embedded-opentype"),
    url(./fonts/lineto-brown-regular.woff) format("woff"),
    url(./fonts/lineto-brown-regular.ttf) format("truetype"),
    url(./fonts/lineto-brown-regular.svg#lineto-brown-regular) format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "BrownWeb";
  src: url(./fonts/lineto-brown-bold.eot);
  src: url(./fonts/lineto-brown-bold.eot?#iefix) format("embedded-opentype"),
    url(./fonts/lineto-brown-bold.woff) format("woff"),
    url(./fonts/lineto-brown-bold.ttf) format("truetype"),
    url(./fonts/lineto-brown-bold.svg#lineto-brown-bold) format("svg");
  font-weight: bold;
  font-style: normal;
}

body {
  font-family: $font-family;
  background-color: $white;
  color: $black;
  font-size: $font-size;
  line-height: $line-height;
}

a {
  color: $black;
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

.Typewriter {
  display: inline;
}

@media only screen and (min-width: $screen-sm-min) {
  body {
    font-size: $font-size-sm;
    line-height: $line-height-sm;
  }
}

.pac-container,
.pac-item {
  z-index: 2147483647 !important;
}
