@import "../../styles/config.scss";

.wrapper {
  $width: 360px;

  background: white;
  position: fixed;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: -1 * $width;

  width: 100%;
  max-width: $width;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);

  overflow: auto;

  transition: left 150ms ease-in;

  &.show {
    left: 0;
  }
}
