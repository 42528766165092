@import "../../../styles/config.scss";

.wrapper {
  background: $black;
  min-height: 100vh;
  flex: 0 0 $unit * 4.5;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  padding: $unit 0 $unit * 0.5;
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
  z-index: 4;

  p {
    color: $white;
  }
}

.avatarWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $white;

  figure {
    margin-bottom: $unit * 0.5;
  }

  p {
    margin-bottom: 0;
    text-transform: uppercase;

    img {
      width: $unit * 0.5;
      vertical-align: middle;
      margin-right: $unit * 0.125;
    }
  }

  .avatarLocation {
    color: $dark-grey;
    text-transform: initial;
  }
}

.navigationWrapper {
  padding-top: $unit * 1.5;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  > *:last-child {
    margin-top: auto;
    margin-bottom: 0;
  }

  a {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: $unit * 0.5;
    padding: $unit * 0.5 0;
    transition: opacity $transition-fast ease-in-out;
    text-decoration: none;

    &:hover {
      opacity: 0.5;
    }

    &.active {
      background: $dark-grey;
    }
  }
  img {
    margin-bottom: $unit * 0.5;
  }
  p {
    text-transform: uppercase;
  }

  .navigationDivider {
    height: 1px;
    margin: 0 $unit * 0.5 $unit;
    background: $white;
  }
}
