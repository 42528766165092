@import "../../../../styles/config.scss";

.wrapper {
  position: relative;
}

.scrollWrapper {
  padding: $unit * 0.5 0;
  overflow-y: auto;
  height: 100vh;
}

.newMessagesWrapper {
  position: absolute;
  padding: $unit;
  background: $light-red;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  p {
    color: $red;
  }
}
