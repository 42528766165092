@import "../../../styles/config.scss";

.wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  background: $light-grey;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  overflow: auto;

  p {
    margin-bottom: 0;
  }
}

.header {
  display: flex;
  align-items: center;
  padding: $unit;
}

.menu {
  display: flex;
  margin-left: $unit;

  > * {
    margin-right: $unit;

    span {
      padding: $unit * 0.5 $unit;
      background: $white;
      cursor: pointer;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.content {
  width: 100%;
  height: 100%;
}
