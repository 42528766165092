@import "../../../styles/config.scss";

.mainWrapper {
  position: relative;
}

.wrapper {
  background-color: $light-grey;
  box-shadow: 0 $box-shadow-default $box-shadow-default-blur $box-shadow-default
    $grey;
  border-radius: 50%;
  width: $unit * 3.5;
  height: $unit * 3.5;
  border: $unit * 0.125 solid $white;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-shrink: 0;

  img {
    fill: green;
  }
}

.wrapperMedium {
  width: $unit * 3;
  height: $unit * 3;

  img {
    max-width: $unit * 3;
  }
}

.wrapperDefault {
  background: $green;
}

.wrapperAlternative {
  background: $yellow;
}

.wrapperSecondary {
  background: $light-yellow;
}

.wrapperSpecial {
  background: $black;
}

.number {
  color: $white;
  font-size: $font-size-big;
}

.crown {
  position: absolute;
  width: $unit * 3;
  top: -$unit;
  left: calc(50% - #{$unit * 1.5});
}
.sunglasses {
  position: absolute;
  width: $unit * 3;
  top: $unit * 0.25;
  left: calc(50% - #{$unit * 1.5});
}
