@import "../../../../styles/config.scss";

.wrapper {
  padding: $unit;
}

.header {
  margin-bottom: $unit;
  height: $unit * 3;
}

.label {
  font-weight: normal;
  display: block;
  margin-bottom: $unit * 0.5;
}

.fileInput {
  margin-bottom: $unit;
}

.buttons {
  display: flex;

  > * {
    margin-right: $unit * 0.5;

    &:last-child {
      margin-right: 0;
    }
  }
}
