@import "../../../../styles/config.scss";

.contentWrapper {
  text-align: center;
}

.row {
  display: flex;
  justify-content: center;
  margin-bottom: $unit;

  > * {
    + * {
      margin-left: $unit;
    }
  }

  p {
    margin-bottom: 0;
  }
}

.submitButton {
  max-width: $unit * 10;
  margin-left: auto;
  margin-right: auto;
}
