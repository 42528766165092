@import "../../../styles/config.scss";

.paragraph {
  font-size: $font-size;
  line-height: $line-height;
  margin-bottom: $unit;

  &:last-child {
    margin-bottom: 0;
  }

  strong {
    font-weight: 700;
  }

  &.small {
    font-size: $font-size-small;
    line-height: $line-height-small;
  }

  &.medium {
    font-size: $font-size-medium;
    line-height: $line-height-medium;
  }

  &.intermediate {
    font-size: $font-size-intermediate;
    line-height: $line-height-intermediate;
  }

  &.big {
    font-size: $font-size-big;
    line-height: $line-height-big;
    margin-bottom: $unit * 2;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.error {
  color: $red;
}

.success {
  color: $green;
}

.strong {
  font-weight: 700;
}

.maxLines {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.maxLines-2 {
  white-space: initial;
  display: block;
  display: -webkit-box;
  max-width: 100%;
  max-height: $line-height-sm * 2;
  font-size: $font-size-sm;
  line-height: $line-height-sm;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

// @media only screen and (min-width: $screen-sm-min) {
.paragraph {
  font-size: $font-size-sm;
  line-height: $line-height-sm;

  &.big {
    font-size: $font-size-sm-small;
    line-height: $line-height-sm-big;
  }

  &.medium {
    font-size: $font-size-sm-medium;
    line-height: $line-height-sm-medium;
  }

  &.big {
    font-size: $font-size-sm-big;
    line-height: $line-height-sm-big;
  }

  &.small {
    font-size: $font-size-sm-small;
    line-height: $line-height-sm-small;
  }
}

// }
