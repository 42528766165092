@import "../../../../styles/config.scss";

.wrapper {
  padding: $unit $unit * 1.5 $unit * 1.5;
  border-bottom: 1px solid $light-grey;
  position: relative;

  > * {
    display: flex;

    align-items: center;

    > :first-child {
      flex-grow: 1;
    }

    > * {
      margin-right: $unit;

      &:last-child {
        margin-right: 0;
      }

      p {
        margin-bottom: 0;
      }
    }

    + * {
      margin-top: $unit * 0.25;
    }
  }

  .toggleWrapper {
    $width: $unit * 14;

    border-radius: 6px;
    background: $white;
    position: absolute;
    z-index: 1;
    width: $width;
    display: flex;
    left: calc(50% - #{$width * 0.5});
    bottom: $unit * -1;

    button {
      margin: 0;

      &:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 0;
      }

      + button {
        margin-left: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: 0;
      }
    }
  }
}

.nameStatusWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.metaInformation {
  color: $dark-grey;
  text-transform: uppercase;
}

.metaIcon {
  font-size: $font-size-sm-medium;
  vertical-align: middle;
  margin-right: $unit * 0.125;

  &:not(:first-child) {
    margin-left: $unit * 0.5;
  }
}

.notes {
  color: $dark-grey;
}

.toPlaces {
  cursor: pointer;
}
