@import "../../../../styles/config.scss";

.imageWrapper {
  margin-top: -1 * $unit;
  margin-left: -1 * $unit;
  margin-right: -1 * $unit;
  margin-bottom: $unit;
  position: relative;
}

.closeButton {
  text-decoration: none;
  font-weight: bold;
  border-radius: $unit;
  width: $unit * 2;
  height: $unit * 2;
  text-align: center;
  line-height: $unit * 2;
  background: $black;
  opacity: 0.8;
  position: absolute;
  top: $unit * 0.5;
  right: $unit * 0.5;

  &:before,
  &:after {
    position: absolute;
    left: $unit - 1;
    top: ($unit / 2) + 3;
    content: " ";
    height: $unit * 0.75;
    width: 3px;
    background-color: $white;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
}

.wrapper {
  h3 {
    margin-bottom: 0;
  }
}

.locationType {
  color: $dark-grey;
}

.buttons {
  display: flex;
  margin-left: -1 * $unit;
  margin-right: -1 * $unit;
  padding: $unit * 0.5 $unit;

  border-top: 1px solid $grey;

  > * {
    margin-right: $unit;

    &:last-child {
      margin-right: 0;
    }
  }
}

.address {
  line-height: 2;
  border-top: 1px solid $grey;
  margin-left: -1 * $unit;
  margin-right: -1 * $unit;
  padding: $unit;
  white-space: pre-line;
}
