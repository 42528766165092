@import "../../../styles/config.scss";

.select {
  width: 100%;
  max-width: $unit * 20;
  display: block;
  height: $unit * 2;
  margin-bottom: $unit;
  margin-top: $unit;
  font-size: $font-size-medium;
  line-height: $line-height-medium;
  padding: 0 $unit * 0.5;
  border-radius: $unit * 0.5;
  appearance: none;
}
