@import "../../../styles/config.scss";

.wrapper {
  display: flex;
  flex-direction: row;

  width: 100%;

  > * {
  }
}

.chatList {
  // flex-basis: $unit * 24;
  flex-grow: 0;
  flex-shrink: 0;
  width: 25%;
  position: relative;
}

.chatListList {
  height: calc(100vh - #{$unit * 5} - 3px - 126px);
  overflow-y: auto;
}

.chat {
  flex-grow: 1;
  border-left: 1px solid $grey;
  border-right: 1px solid $grey;
  max-width: calc(55%);
}

.systemMessages {
  width: 20%;
  flex-grow: 0;
  flex-shrink: 0;
  box-shadow: inset 4px 0 7px -7px rgba(0, 0, 0, 0.5);
  position: relative;
  z-index: 1;
  background: $light-grey;
}

.chatBroadcastItem {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
