@import "../../../../styles/config.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.messagesContainer {
  display: flex;
  flex-grow: 1;
  height: 0;

  > * {
    width: 100%;
  }
}

.formContainer {
  display: flex;
  flex-direction: column;

  > * {
    width: 100%;
  }
}

.mapContainer {
  display: flex;
  flex-grow: 1;

  > * {
    width: 100%;
  }
}

.hidden {
  display: none;
}
