@import "../../../../styles/config.scss";

.wrapper {
  border-top: 1px solid $light-grey;
  display: flex;
  padding: $unit 0;
  cursor: pointer;
  transition: opacity 300ms ease-in;

  &:not(.active):hover {
    opacity: 0.6;
  }

  &:first-child {
    border-top: 0;
  }

  >* {
    padding: 0 $unit * 0.5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-width: 40px;
  }

  p {
    margin-bottom: 0;

    strong {
      color: $primary-color;
    }
  }
}

.active {
  background: $light-green;
  border-bottom: 1px solid $green;
  border-top: 1px solid $green;

  +.wrapper {
    border-top: 0;
  }
}

.hub {
  img {
    margin-bottom: $unit * 0.25;
  }
}

.avatar {
  flex: 0 0 $unit * 5;
}

.message {
  align-items: flex-start;
  text-align: left;
  min-width: 0;
  display: block;
  padding-top: $unit * 0.25;

  p:not(:first-child) {
    color: $dark-grey;
  }
}