@import "../../../../styles/config.scss";

.wrapper {
  background: $white;
  padding: $unit $unit $unit $unit * 3;
  display: flex;
  border-top: 2px solid $light-grey;
  cursor: pointer;

  transition: opacity 300ms ease-in;

  &:not(.active):hover {
    opacity: 0.6;
  }

  p {
    margin-bottom: 0;
  }

  > * {
    margin-right: $unit;

    &:last-child {
      margin-right: 0;
    }
  }
}

.message {
  min-width: 0;
  p:not(:first-child) {
    color: $dark-grey;
  }
}

.active {
  background: $light-green;
  border-top-color: $green;
}
