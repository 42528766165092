@import "../../../../styles/config.scss";

.wrapper {
  padding: $unit * 0.5 $unit;

  + .wrapper {
    border-top: 1px solid $grey;
  }
}

.time {
  text-transform: uppercase;
  color: $dark-grey;
}

.header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  > :first-child {
    margin-right: $unit * 0.5;
  }

  > :last-child {
    padding-left: $unit * 0.5;
    margin-left: auto;
  }

  p {
    margin-bottom: 0;
  }
}

.extraWrapper {
  padding: $unit 0 $unit * 0.5;
}

.person {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: $unit;

  > :first-child {
    margin-right: $unit * 0.5;
  }

  p {
    margin-bottom: 0;
  }
}

.meta {
  border-top: 1px solid $grey;
  border-bottom: 1px solid $grey;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $unit;
  padding: $unit * 0.5 0;

  p {
    margin-bottom: 0;
    line-height: 1;
  }
}

p.titleTags {
  margin-top: $unit * 0.25;
  margin-bottom: $unit * 0.25;
}

.toPlaces {
  cursor: pointer;
}
