$font-family: "BrownWeb", sans-serif;
$font-family-alternative: "BrownWeb", sans-serif;

$primary-color: #000;
$secondary-color: #00ffe1;
$secondary-color-a5: #00ffe1a5;
$black: #000;
$white: #fff;
$red: #d20909;
$light-red: #fae2e2;
$grey: #d8d8d8;
$very-light-grey: #f9f9f9;
$light-grey: #eeeeee;
$dark-grey: #868686;
$yellow: #e4bb02;
$light-yellow: #fdd936;

$green: #44bfb4;
$light-green: #e2f4f3;

$line-height: 22px;
$font-size: 12px;

// Mobile
$line-height-small: 23px;
$font-size-small: 8px;

$line-height-medium: 24px;
$font-size-medium: 16px;

$line-height-intermediate: 28px;
$font-size-intermediate: 24px;

$line-height-big: 48px;
$font-size-big: 32px;

// SM+
$line-height-sm-small: 20px;
$font-size-sm-small: 12px;

$line-height-sm: 20px;
$font-size-sm: 16px;

$line-height-sm-medium: 24px;
$font-size-sm-medium: 20px;

$line-height-sm-big: 60px;
$font-size-sm-big: 48px;

$transition-fast: 100ms;
$transition-medium: 300ms;

$unit: 20px;

$screen-sm-min: 768px;
$screen-lg-min: 1024px;
$screen-xl-min: 1200px;

$box-shadow-default: 2px;
$box-shadow-default-blur: 5px;
